@import url(https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@500&display=swap);
/*
App Name: EveryShift
Author: Phan Huong
Author URI: https://phanhuong.com/
Description: CSS Styling for EveryShift
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/

/*
01. GLOBAL

RESPONSIVE WITH BOOTSTRAP
    Screen < 1200px
    Screen < 992px
    Screen < 768px
    Screen <= 576px
    Screen <= 480px
    Screen <= 320px
*/

/* 01. GLOBAL */

* {
  box-sizing: border-box;
  font-family: 'Be Vietnam', sans-serif;
}

html, body, h6, h5, h4, h3, h2, h1 {
  font-family: 'Be Vietnam', sans-serif;
}

body {
  margin: 0;
  /* background: #4d4d4d; */
  /* background: #F2F2F2!important; */
  background: white;
}

.background-dark {
  background: #333333;
}

.center {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#root {
  height: 100%;
  padding: 0;
  margin: 0;
}

.page_wrapper {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
}

main {
  -webkit-flex: 2 1;
          flex: 2 1;
  overflow: auto;
  margin: 0.5rem 0;
  padding: 0;
}
.mainHeader {
    width: 100%;
    height: 3.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    background: #274B7D;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.60);
    padding: 0 1rem;
    z-index: 5;
  }
  
  @media (min-width: 768px) {
    .mainHeader {
      -webkit-justify-content: space-between;
              justify-content: space-between;
    }
  }
#sideDrawer {
    position: fixed;
    left: -308px;
    top: 56px;
    bottom: 64px;
    z-index: 100;
    width: 300px;
    background: #454545;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.75);
    overflow-x: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }

#sideDrawer a {
    display: none;
}

.drawerOpen a {
      display: block!important;
      text-decoration: none;
      color: white;
  }

.drawerOpen {
      left: 0px!important;
}
.sideDrawerWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.nav_icon_btn {
    border-radius: 10px;
    margin: 1.5rem;
    height: 100px;
    width: 100px;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.nav_icon_img {
    font-size: 2rem;
    margin-bottom: 0;
}

.headerUserContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 56px;
}

/* .userNavBtn img {
    width: 48px;
    height: 48px;
    background: transparent;
    border: none;
    margin-right: 1rem;
    cursor: pointer;
  } */

.userNavBtn {
  -webkit-transition: -webkit-transform .5s ease-in-out;
  -ms-transition: -ms-transform .5s ease-in-out;
}

.navbar_icon {
  font-size: 1.6rem;
  color: white;
  margin-right: 1rem;
  cursor: pointer;
  transition: -webkit-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
  transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
}
.navbar_rotate {
  transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  -webkit-transform:rotate(90deg);
  transition: -webkit-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
  transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
}

/* .navbar_icon:hover {
  transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  -webkit-transform:rotate(90deg);
} */

.headerUsername {
  color: white;
  font-size: 1.3rem;
  margin-bottom: 4px;
}

.headerUsername a {
  text-decoration: none;
  color: white;
  text-align: left;
}

.headerUsername a:hover {
  text-decoration: none;
  color: white;
}

.headerDate {
  color: white;
  margin-bottom: 0;
  font-size: 1rem;
  font-style: italic;
}

.navFooterTitle {
  color: white;
}

.navFooterTitle a {
  text-decoration: none;
  color: white;
}

.clockIcon {
  font-size: 22px!important;
  position: relative;
  top: 3px;
  left: -7px;
}

.main-navigation__header-nav {
  display: none;
}

.navSideDrawer {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
.mainFooter {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
    background-color: white;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.26);
    padding: 0px;
    -webkit-align-content: center;
            align-content: center;
    border-top: 1px solid #4F4F4F;
  }
  
.navIcon img {
  height: auto;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.navIcon i {
  font-size: 36px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14px;
  margin-bottom: 14px;
  color: black;
}

.navIcon:hover i, .navIcon:active i {
  color: white;
}

.navIcon {
  text-align: center;
  height: auto;
  display: inline-block;
  text-decoration: none;
}

.navIcon div {
  text-align: center;
  height: auto;
}

.navIcon:hover, .navIcon:active  {
  background-color: #274B7D;
}

@media (min-width: 768px) {
  /* .mainFooter {
    justify-content: space-between;
  } */
}
.usersList {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 90%;
    max-width: 50rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
.avatar {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex; 
  -webkit-justify-content: center; 
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.avatar img {
  display: block;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card {
  position: relative;
  margin: 0;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.50); */
  border-radius: 6px;
  border: transparent;
  padding: 1rem;
  overflow: hidden;
  background: transparent;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.35);
}

.userItem {
    margin: 1rem;
    width: calc(45% - 2rem);
    min-width: 17.5rem;
  }
  
  .userItem a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding: 1rem;
    background: #E7E7E7;
    /* background: #EB5757; */
  }
  
  .userItem a:hover,
  .userItem a:active {
    /* background: #ffd900; */
    background: #454545;
  }
  
  .userContent {
    padding: 0;
  }
  
  .userImage {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }
  
  .userInfo {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }

  .userInfo p {
    margin: 0;
    font-weight: normal;
    color: black;
  }

  .userItem:hover p,
  .userItem:active p,
  .userItem:hover p,
  .userItem:active p {
    color: white;
  }

  .userInfoName {
    font-size: 1.3rem;
    font-style: bold;
  }

  .userPlace {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

.about_page {
    height: 100%;
    overflow: hidden;
}
.about_wrapper {
    height: 100%;
    display: grid;
    padding: 0 2rem;
    grid-template-rows: 3rem auto;
}
.about_wrapper > .title_section {
    font-size: 1.4rem;
    -webkit-align-self: start;
            align-self: start;
    justify-self: center;
}
.about_wrapper > .main_content {
    height: 100%;
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
}
.about_wrapper > .main_content > .logo {
    display: grid;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    grid-gap: 0.1rem;
    gap: 0.1rem;
}
.signupFormContainer {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
}

.signupFormContainer h4 {
    padding-bottom: 1rem;
}

.signupFormContainer form div {
    padding-bottom: 1rem;
}

.checkTerms {
    padding-left: 1.25rem;
}

.formBtn {
    background-color: #274B7D;
    color: white;
}

.formBtn:hover {
    background-color: #454545;
    color: white;
}

.field_2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
}

.signupFormContainer form .action_buttons {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: auto auto;
    -webkit-align-items: center;
            align-items: center;
}

.signupFormContainer form .action_buttons > .left_btns {
    justify-self: start;
}

.signupFormContainer form .action_buttons > .right_btns {
    justify-self: end;
}
.custom_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .8);
    display: none;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 1030;
}
.custom_modal_wrapper {
    background-color: #274B7D;
    width: 90%;
    height: auto;
    max-height: 90%;
    padding: 1rem;
    color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.custom_modal_header {
    display: grid;
    grid-template-columns: auto auto;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed darkgray;
}
.custom_modal_header > .title {
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.2rem;
}
.custom_modal_header > .title > i.fa {
    margin-right: 0.5rem;
}
.custom_modal_header > .close_btn {
    text-align: right;
}
.custom_modal_header > .close_btn > button {
    color: white;
    background: none;
    border: 0;
}
.custom_modal_content {
    -webkit-flex: 2 1;
            flex: 2 1;
    padding: 0.5rem 0;
    overflow: hidden;
}
.custom_modal_content > .main_content {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
}
.custom_modal_footer {
    display: grid;
    grid-template-columns: auto auto;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 0.5rem;
    border-top: 1px dashed darkgray;
}
.custom_modal_footer > .status {
    text-align: left;
}
.custom_modal_footer > .buttons {
    text-align: right;
}
.custom_modal_footer > .buttons > button {
    margin-left: 0.5rem;
}
.calendar_page {
    text-align: center;
    height: 100%;
    /* background: lightcoral; */
    overflow: hidden;
}

.calendar_wrapper {
    height: 100%;
    display: grid;
}

.calendar_controller {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    -webkit-align-items: center;
            align-items: center;
    margin: 0.5rem;
}

.calendar_controller p {
    margin-bottom: 5px!important;
}

.calender_inner_wrapper {
    display: grid;
    grid-template-columns: 40px auto;
    height: 100%;
    overflow: hidden;
}

.days_of_week_label {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* font-weight: bold; */
}
.hours_of_day_label {
    border-bottom: 1px solid darkgray;
    border-right: 1px solid darkgray;
}
.single_day_of_week_label {
    display: grid;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
    background-color: lightblue;
    border-top: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
    border-right: 1px solid darkgray;
    font-size: 0.85rem;
}
.calendar_main_wrapper {
    height: 100%;
    overflow-y: auto;
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 40px auto;
}
.time_of_day {
    position: relative;
    /* height: 1440px; */
    background-color: lightblue;
    border-right: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
}
.single_time {
    font-size: 0.85rem;
    line-height: 0.85rem;
    position: absolute;
    width: 40px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
}
.days_of_week {
    height: 100%;
    width: 100%;
    background: rgb(235, 250, 255);
    position: relative;
}
.hour_line {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    border-bottom: 1px dashed lightgray;
}
.days_of_week_content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}
.days_of_week_content > .single_day_content {
    border-right: 1px solid lightgray;
    position: relative;
}
.shift_item {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 0.8rem;
    background-color: #454545; /* #274B7D or #454545 */
    display: grid;
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    box-sizing: border-box;
    opacity: 1;
}
.shift_item_multiple {
    grid-template-columns: auto auto;
}
.shift_item > i {
    line-height: 0.8rem;
}
#edit_shift_modal .employee_list .list-group-item {
    display: grid;
    grid-template-columns: auto auto;
    -webkit-align-items: center;
            align-items: center;
    padding: .25rem .75rem;
}
#edit_shift_modal .employee_list .list-group-item > div {
    display: grid;
    justify-self: start;
}
#edit_shift_modal .employee_list .list-group-item > div > span:last-child {
    font-size: 0.8rem;
      font-style: italic;
}
#edit_shift_modal .employee_list .list-group-item > i {
    justify-self: end;
}
.create_shift_section {
    display: grid;
    padding: 0.5rem 0.5rem 0 0.5rem;
}
  .timesheet_wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .nav-link.active {
    background-color: #274B7D!important;
  }
  .finishedShifts.nav-link.active {
    background-color: green!important;
  }
  .nav-item.nav-link {
    color: black;
    border-radius: 10px 10px 0 0!important;
    border-bottom: 1px solid #274B7D;
  }
.wishlist_controller {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: 1rem;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.wishlist_btn {
    line-height: 1rem;
}
.sort_by select {
    margin-left: 0.5rem;
    border-radius: 10px;
    text-align: center;
}
.sort_by label {
    margin-bottom: 0;
}
.table td {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}
.wishlist_table {
    font-size: 1rem;
    text-align: center;
}
.wishlist_actions {
    padding: 0.3rem!important;
    font-size: 1.5rem;
}
.wishlist_actions a {
    color: #274B7D;
    line-height: 1.5rem;
}
.wishlist_actions button {
    border: none;
    background-color: transparent;
    color: #274B7D;
}
.wishlist_pending {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    padding: 0.3rem 0 0.3rem 0;
    line-height: 1.5rem;
}
.wishlist_controller p {
    margin-bottom: 0;
    font-weight: bolder;
}
.pay_check {
    text-align: center;
    background-color: lightblue;
    padding: 0.5rem;
}
.daysoff_page {
    height: 100%;
    overflow: hidden;
}
.daysoff_wrapper {
    height: 100%;
    display: grid;
    padding: 0 2rem;
    grid-template-rows: 3rem auto 3rem;
}
.daysoff_wrapper > .title_section {
    font-size: 1.4rem;
    -webkit-align-self: start;
            align-self: start;
}
.daysoff_wrapper > .main_content {
    height: 100%;
    /* display: grid; */
    overflow-x: hidden;
    overflow-y: auto;
}
.daysoff_wrapper > .add_days_off_section {
    padding-top: 0.5rem;
    -webkit-align-self: end;
            align-self: end;
}
.daysoff_wrapper > .add_days_off_section > button {
    width: 100%;
}
.year_list > .year_item {
    border-radius: 0.25rem;
}
.year_list > .year_item > .year_item_link {
    border-radius: 0.25rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    -webkit-align-items: center;
            align-items: center;
}
.year_list > .year_item > .year_item_link > .title {
    justify-self: start;
}
.year_list > .year_item > .year_item_link > .icon {
    justify-self: end;
}
.qrcode_page {
    height: 100%;
    overflow: hidden;
}
.qrcode_wrapper {
    height: 100%;
    display: grid;
    padding: 0 2rem;
    grid-template-rows: 3rem auto 3rem;
}
.qrcode_wrapper > .title_section {
    font-size: 1.4rem;
    -webkit-align-self: start;
            align-self: start;
    justify-self: center;
}
.qrcode_wrapper > .main_content {
    height: 100%;
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-align-items: center;
            align-items: center;
    justify-items: center;
}
.qrcode_wrapper > .buttons_section {
    padding-top: 0.5rem;
    -webkit-align-self: end;
            align-self: end;
    justify-self: center;
    text-align: center;
}
.qrcode_wrapper > .buttons_section > button {
    width: 100%;
}
.shift_today_wrapper {
    border-radius: 10px;
    background-color: #F0F0F0;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.35);
}

.work_day {
    display: grid;
    grid-template-columns: 5fr 2fr;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
}

.work_day img {
    max-width: 80%;
    height: auto;
}

.work_day p {
    margin-top: 1rem;
    margin-left: 1rem;
}
.upcoming_shifts_wrapper {
    margin-top: 2rem;
}

.upcoming_shifts_container {
    margin-top: 2rem;
}

.upcoming_shifts_wrapper h5 {
    margin-bottom: 0;
}

.upcoming_shift {
    margin-top: 1rem;
}

.upcoming_shift_date {
    background-color: #274B7D;
    border-radius: 10px 10px 0 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    margin-bottom: 0;
}

.upcoming_shift_info {
    border: 1px solid #C8C8C8;
    border-radius: 0 0 10px 0px;
    padding: 1rem;
}

.upcoming_shift_info p {
    margin-bottom: 0;
}

.no_upcoming_shifts {
    text-align: center;
}

.no_upcoming_shifts_text {
    border: 1px solid #C8C8C8;
    border-radius: 10px 0 10px 0;
    text-align: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem 1rem 0 1rem;
    color: #274B7D;
}

.no_upcoming_shifts_text p:last-child {
    font-size: 0.9rem;
    color: black;
}

.no_upcoming_shifts_text:hover {
    background-color: #454545;
    color: white;
}

.no_upcoming_shifts_text:hover p:last-child {
    color: white;
}

.add_to_wishlist_btn {
    margin-top: 2rem;
}
.home_wrapper {
    margin: 1rem 2rem 1rem 2rem;
}
.size_full_screen {
    width: 100vw;
    height: 100vh;
    display: grid;
    -webkit-align-items: center;
            align-items: center;
}

.signinFormContainer {
    /* padding-top: 30%; */
    padding-left: 10%;
    padding-right: 10%;
    /* color: #274B7D; */
    color: #307EEA;
}

.signinFormContainer h4 {
    padding-bottom: 1rem;
}

.signinFormContainer form div {
    padding-bottom: 1rem;
}

.signInLink {
    text-align: center;
}

.signInLink a {
    font-style: italic;
    padding-top: 0.75rem;
    color: #307EEA;
}

.signInLink a:hover {
    color: white;
}

.goBtn {
    text-align: right;
}

.goBtn button {
    border: solid 2px #307EEA;
    background-color: transparent;
    border-radius: 8px;
    color: #307EEA;
    
}

.goBtn button:hover {
    border: solid 2px white;
    color: white;
}

.signinLogo {
    color: white;
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
}
