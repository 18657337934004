.signupFormContainer {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
}

.signupFormContainer h4 {
    padding-bottom: 1rem;
}

.signupFormContainer form div {
    padding-bottom: 1rem;
}

.checkTerms {
    padding-left: 1.25rem;
}

.formBtn {
    background-color: #274B7D;
    color: white;
}

.formBtn:hover {
    background-color: #454545;
    color: white;
}

.field_2col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
}

.signupFormContainer form .action_buttons {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.signupFormContainer form .action_buttons > .left_btns {
    justify-self: start;
}

.signupFormContainer form .action_buttons > .right_btns {
    justify-self: end;
}