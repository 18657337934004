.mainHeader {
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #274B7D;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.60);
    padding: 0 1rem;
    z-index: 5;
  }
  
  @media (min-width: 768px) {
    .mainHeader {
      justify-content: space-between;
    }
  }