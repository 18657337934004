.calendar_page {
    text-align: center;
    height: 100%;
    /* background: lightcoral; */
    overflow: hidden;
}

.calendar_wrapper {
    height: 100%;
    display: grid;
}

.calendar_controller {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    margin: 0.5rem;
}

.calendar_controller p {
    margin-bottom: 5px!important;
}

.calender_inner_wrapper {
    display: grid;
    grid-template-columns: 40px auto;
    height: 100%;
    overflow: hidden;
}

.days_of_week_label {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* font-weight: bold; */
}
.hours_of_day_label {
    border-bottom: 1px solid darkgray;
    border-right: 1px solid darkgray;
}
.single_day_of_week_label {
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: lightblue;
    border-top: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
    border-right: 1px solid darkgray;
    font-size: 0.85rem;
}
.calendar_main_wrapper {
    height: 100%;
    overflow-y: auto;
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 40px auto;
}
.time_of_day {
    position: relative;
    /* height: 1440px; */
    background-color: lightblue;
    border-right: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
}
.single_time {
    font-size: 0.85rem;
    line-height: 0.85rem;
    position: absolute;
    width: 40px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
}
.days_of_week {
    height: 100%;
    width: 100%;
    background: rgb(235, 250, 255);
    position: relative;
}
.hour_line {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    border-bottom: 1px dashed lightgray;
}
.days_of_week_content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}
.days_of_week_content > .single_day_content {
    border-right: 1px solid lightgray;
    position: relative;
}
.shift_item {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 0.8rem;
    background-color: #454545; /* #274B7D or #454545 */
    display: grid;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 0.4rem;
    box-sizing: border-box;
    opacity: 1;
}
.shift_item_multiple {
    grid-template-columns: auto auto;
}
.shift_item > i {
    line-height: 0.8rem;
}
#edit_shift_modal .employee_list .list-group-item {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: .25rem .75rem;
}
#edit_shift_modal .employee_list .list-group-item > div {
    display: grid;
    justify-self: start;
}
#edit_shift_modal .employee_list .list-group-item > div > span:last-child {
    font-size: 0.8rem;
      font-style: italic;
}
#edit_shift_modal .employee_list .list-group-item > i {
    justify-self: end;
}
.create_shift_section {
    display: grid;
    padding: 0.5rem 0.5rem 0 0.5rem;
}