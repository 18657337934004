.card {
  position: relative;
  margin: 0;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.50); */
  border-radius: 6px;
  border: transparent;
  padding: 1rem;
  overflow: hidden;
  background: transparent;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.35);
}
