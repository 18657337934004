.custom_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .8);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1030;
}
.custom_modal_wrapper {
    background-color: #274B7D;
    width: 90%;
    height: auto;
    max-height: 90%;
    padding: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
}
.custom_modal_header {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed darkgray;
}
.custom_modal_header > .title {
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.2rem;
}
.custom_modal_header > .title > i.fa {
    margin-right: 0.5rem;
}
.custom_modal_header > .close_btn {
    text-align: right;
}
.custom_modal_header > .close_btn > button {
    color: white;
    background: none;
    border: 0;
}
.custom_modal_content {
    flex: 2;
    padding: 0.5rem 0;
    overflow: hidden;
}
.custom_modal_content > .main_content {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
}
.custom_modal_footer {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding-top: 0.5rem;
    border-top: 1px dashed darkgray;
}
.custom_modal_footer > .status {
    text-align: left;
}
.custom_modal_footer > .buttons {
    text-align: right;
}
.custom_modal_footer > .buttons > button {
    margin-left: 0.5rem;
}